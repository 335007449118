.dash-summary {
    margin-top: 30px;
}

/* .dash-thead, .dash-tbody{
    font-size: 12px !important;
} */
thead th, tbody td{
    font-size: 12px !important;

}

.my-bell {
    color: #00979E;
    font-size: 25px;
    margin: 5px 10px 0 0;
}

.card-data {
    margin-top: 10px;
}

.dash-title,
.card-title {
    color: #00979E;
    font-size: 16px;
    font-weight: 500;
}

.card-title {
    margin: 10px 0;
}

.mytable,
.payment-card {
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
    border: none;
    height: 100%;
}

.act-card {
    border: none;
    /* box-shadow: 0px 0px 8px rgb(0 0 0 / 10%); */
    height: 80px;
    border-radius: 5px;
    box-shadow: 0 0px 0px 0 rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 19%);
}

.vendor {
    border: none;
    box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 10%) !important;
    border-radius: 5px;
    margin-bottom: 10px;
    /* padding: 5px 10px; */
}
.vendor-card{
    padding: 5px 0px;
    /* display: flex; */
}

.card-row-content{
    display: flex;
    align-items: center;
    padding: 5px;
}

.card-row-content .card-rtext{
    vertical-align: middle;
    /* margin-right: 10px; */
    /* margin-top: 10px; */
}
.card-row {
    padding: 0px 10px !important;
}

.input-group{
    position: relative;
    align-items: center;
}

.search-dash-summary{
    border: 1px solid lightgray;
    border-radius: 15px !important;
    padding: 8px 20px;
    font-size: 13px;
    width: 400px !important;   
    box-shadow: 0px 2px 4px -4px;
    position: relative;
}
.search-dash-summary:focus {
    border: 1px solid #00979E;
}

.fa-search.dash{
    position: absolute !important;
    left: 370px !important;
    top: 0px !important;
    color: #00979E;
    opacity: 0.5;
}

.actives,
.invoice,
.payment,
.po {
    height: 45px;
    width: 45px;
    border-radius: 12%;
    display: flex;
    vertical-align: middle !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
}

.actives {
    background-color: rgba(255, 122, 0, 0.15);
}

.invoice {
    background-color: rgba(239, 71, 112, 0.15);
}

.payment {
    background-color: rgba(0, 151, 158, 0.15);
}

.po {
    background: rgba(255, 218, 42, 0.15);
}

.user {
    border-radius: 0.25rem !important;
}

.act-body {
    margin: -5px 0;
    color: rgba(136, 136, 136, 0.5);
    font-size: 14px;
    font-weight: 400;
}

.act-num {
    font-size: 22px;
    color: #2E2E2E;
    font-weight: 500;
}

.summary {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
}

.recent-scroll {
    position: relative;
    /* height: 350px; */
    overflow: auto;
    padding: 5px !important;
    border-radius: 5px;
}

.dash-scroll {
    position: relative;
    height: 280px;
    overflow: auto;
    padding: 0px !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.tbody-scroll-play{
    height: 250px;
}



.vendor-bar-chart .recharts-rectangle.po-chart{
    width: 25px;
}

/* width */
.dash-scroll::-webkit-scrollbar, .recent-scroll::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* cursor: pointer; */
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    /* cursor: pointer; */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 151, 158, 0.3) !important;
    border-radius: 10px;
    /* cursor: pointer; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 151, 158, 0.3) !important;
    /* cursor: pointer; */
}

.table {
    border-radius: 20px !important;
}

.dash-table>:not(:first-child) {
    border-top: none !important;
}

.accordion-item:last-of-type {
    border-radius: 5px;
    height: 30px !important;
    background: transparent;
    z-index: 9;
}

.accordian_sorting {
    padding-top: -5px;
 }
.accordion-item:first-of-type .accordion-button {
    border-radius: 5px;
    background-color: transparent;
}

.accordion-button:focus {
    z-index: 99999;
    box-shadow: none;
}

.accordion-item:first-of-type .accordion-button {
    background-color: transparent;
    color: #00979E;
    padding-left: 10px;
    padding-top: 5px;
}

.accordion-button:not(.collapsed)::after {
    color: #00979E;
}

.accordion-button::after {
    color: #00979E;
}

.sort {
    width: 90%;
    font-size: 12px;
    padding: 5px 10px;
    color: #00979E;
    border: 1px solid #00979E;
    margin: 10px 7px;
    text-align: center;
    cursor: pointer;
}

.accordion-body {
    padding: 3px 2px;
    align-items: center;
    vertical-align: middle;
    justify-content: space-around;
}

.sort-recent-activity {
    width: 100%;
    font-size: 13px;
    color: #000000;
    cursor: pointer;
    padding: 10px 8px;
}
.sort-recent-activity:hover{
    background-color: #c5e9eb;
    /* color: #ffffff; */
}
.recent-activity-part{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.sorting {
    width: 100px;
    font-size: 14px;
    border-color: #00979E;
    background-color: white;
    margin-top: 5px;
    position: absolute;
    right: 20px;
}

.dropdown-sort {
    padding: 0px 10px 10px 10px;
    margin-top: -5px !important;
}

.dropdown-recent-activity {
    display: flex;
    padding: 5px 10px;
    color: #00979E;
    position: relative;
    float: right;
    border-color: #00979E;
    height: 30px !important;
    width: 155px !important;
    justify-content: space-between;
    border-radius: 10px !important;
}
.dropdown-recent-activity:focus, .dropdown-recent-activity:hover{
    color: #00979E;
}
.filtering-by-po-inv-pp{
    border-radius: 10px !important;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 10%);
    z-index: 9 !important;
    background-color: #ffffff !important;
}

.btn-secondary:hover {
    color: #00979E;
    background-color: #ffffff;
    border-color: #00979E;
    box-shadow: none;
}

.acc-body {
    border-radius: 4px !important;
    width: 140px !important;
    z-index: 9999 !important;
    background-color: #ffffff !important;
    margin-top: -25px;
    position: absolute;
    right: 0;
}

.recent-act-card{
    max-height: 300px;
    overflow-y: auto;
}

.accordion-button.collapsed.acc-body-recent {
    box-shadow: none;
    background-color: #ffffff !important;
}

.accord-down {
    padding: 5px;
    margin-left: 10px;
}

.accord-down-recent {
    padding: 5px;
    margin-left: 75px;
}

.accordion-button:not(.collapsed) .accord-down {
    transform: rotate(180deg);
    padding: 5px;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
    background-image: none;
    box-shadow: none;
}

.accordion-button:not(.collapsed) .accord-down-recent {
    transform: rotate(180deg);
    box-shadow: none;
}

.dash-thead {
    color: #888888;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.03rem;
    text-transform: uppercase;
}

.act-link,
.act-link:hover {
    color: #00979E;
    text-decoration: underline;
    font-size: 13px;
}

.dash-head {
    background-color: #F5FBFB !important;
    position: sticky;
    top: 0;
    border-radius: 20px !important;
}

.dash-tbody {
    font-size: 14px;
    font-weight: 400;
}



.vendor:last-child {
    margin: 0 0 0 0;
}

.mycard {
    margin: 0 0 0px 0;
}

.ven-date {
    font-size: 10px;
    font-weight: 400;
    line-height: 9px;
}

.rec-date {
    text-align: right;
    margin:0 -25px 0 0;
}

.icon-ven {
    font-size: 15px;
    font-weight: 500;
}
.pay{
   vertical-align: middle;
}
.pay img{
    /* float: left; */
    max-width: 60px;
    /* margin-right: 10px; */
}

.ven-text {
    font-weight: 400;
    font-size: 12px;
    width: auto;
    word-break: break-all;
}

.sort-ment {
    width: 25px;
    float: right;
    position: relative;
    right: 0;
    bottom: 3px;
   
}

.dash-card {
    margin-top: 30px;
}

/* Very Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) {

    .act-card {
        height: auto;
        margin: 10px 0;
    }

    
    .dash-card {
        margin-top: 20px;
    }
    
    .vendor {
        height: auto;
        /* margin: 15px 0; */
        /* padding: 5px 10px; */
    }
    
    .vendor:last-child {
        margin: 15px 0 0 0;
    }
    /* .emp-content {
        width: 100% !important;
        position: relative;
    } */

    /* .pay {
        width: 50px;
        margin: 0 0 5px 0;
    } */

    ::-webkit-scrollbar-button {
        height: 12px;
    }

    .payment-card {
        margin-top: 20px;
    }

    .actnum {
        margin: -42px 0 0 58px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {

    .act-body    
    {
        margin: -5px 0 0 10px;
        width: 270px;
    }

    .act-card
     {
        width:110%;
    }

    .vendor {
        margin-bottom: 16px;
        /* text-align: justify; */
        height: auto;
        /* padding: 5px 10px; */
    }

    .rec-date {
        text-align: right;
        margin: 0 0 10px -31px;
    }

    .ven-date {
        font-size: 12px;
        font-weight: 400;
        line-height: 9px;
        text-align: justify;
    }

    .ven-text {
        font-weight: 400;
        font-size: 10px;
        /* margin: 0 0 0 -16px; */
        /* width: 145px; */
    }

    .icon-ven {
        font-weight: bold;
        font-size: 12px;
    }

    .actnum {
        margin: -44px 0 0 32px;
    }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width:1200px) {
    .dash-content {
        width: 103%;
        margin-left: -3%;
    }

    .vendor {
        margin-bottom: 16px;
        height: auto;
    }

    .pay {
        width: 90px;
        margin: 0px;
    }

    .ven-text {
        font-weight: 400;
        font-size: 14px;
        /* width: 133%; */
    }

    .act-body {
        margin: -7px 0 -5px 7px;
        width: 100%;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1201px) and (max-width:1400px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1401px) {}