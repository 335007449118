
.emp-content{
    width: 98%;
    position: relative;
}
.employee-summary {
    margin-top: 20px;
    margin-left: 10px;
}

.fa-search:focus{
    color: #00979E;
}
.page-title-box{
    display: flex !important;
}

.act {
    color: #00979E !important;
}

.dot-ac {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    background-color: #00979E;
}

.seleting-menus{
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
}
.page-table-title{
    flex: auto;
    font-weight: 500;
    color: #00979E;
    margin: 1% 0px 1% 0px !important;
}

.seleting-menus .select{
    margin: 5px 5px !important;
    box-shadow: none;
    border: 1px solid #c2c2c2;
    color: #4e4e4e !important;
    max-width: fit-content;
    font-size: 13px !important;
    height: 30px !important;
    border-radius: 10px !important;
}
/* .seleting-menus .select:focus {
    box-shadow: none;
    border: 1px solid #838383;
    color: #d21010;
} */
/* .select .option-label {
    color :#027075;
    background-color: #f5fbfc;
} */

.employee-summary .btn-select-search {
    background-color: #00979E;
    margin-left: 5px;
    color: #ffffff;
    box-shadow: none;
    position: relative;
    align-items: center;
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 15px;
}
.btn-select-search:hover {
    color: #ffffff;
    box-shadow: none;
    background-color: #027075;
}
.btn-select-search:focus {
    box-shadow: none;
}
.btn-inline-item{
    align-items: flex-end;
}
.btn-inline-item h6{
    padding-left: 5px;
    align-items: center;
    margin: 0px;
    line-height: 1.1;
    font-size: 15px;
}
.employee-summary .btn-clear-select{
    background-color: #3f3f3f;
    color: #ffffff;
    align-items: center !important;
    padding: 8px 8px;
    margin-left: 8px;
    font-size: 14px;
    border-radius: 15px;
    line-height: 0;
}
.btn-clear-select:focus {
    box-shadow: none;
    background-color: #3f3f3f;
    color: #ffffff;
}
.btn-clear-select:hover {
    background-color: #070707;
    color: #ffffff;
}

.btn-clear-select .bi-x-circle-fill{
    text-align: center;
    vertical-align: middle;
}
    
.employee-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alert-message-model .modal-content{
    width: 100%;
    border: none;
    padding: 0px !important;
    border-radius: 10px;
}

.delete-employee-title{
    background-color: #04a1a9;
    color: #ffffff;
    justify-content: space-between;
    padding: 5px 20px;
    margin: 10px 0px;
    display: flex;  
    align-items: center;  
}
.delete-employee-title h6{
    margin-bottom: 0px;
}
.delete-employee-title .bi-trash-fill {
    font-size: 18px;
    padding: 8px 0px 4px 5px;
}

.alert-status-msg-delete{
    font-size: 16px;
    margin: 15px 10px;
}
.alert-status-msg-delete .delete-success-msg{
    color: green;
}
.delete-ok-cancel{
    margin: 10px 20px;
    justify-content: space-between;
}
.delete-ok-cancel .btn-ok{
    background-color: rgb(228, 21, 21);
    color: white;
    padding: 4px 10px !important;
    border-radius: 8px;
    margin: 5px;
}
.delete-ok-cancel .btn-cancel-model {
    background-color: black;
    color: white;
    padding: 4px 10px !important;
    border-radius: 8px;
    margin: 5px;
}
.no-data-found{
    position: absolute;
    transform: translate3d(400px, 50px, 10px);
}
.page-range>p{
    margin-bottom: 0px;
}
/* INPUT FIELDS START */
/* .employee-select {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; 
    border: 1px solid #00979E;  
    color: #00979E !important;
    height: 30px;
    width: 100px;
    margin-left: 10px;
}
.employee-select:focus{
    box-shadow: none;
    border: 1px solid #00979E;
} */
.emp-input{
    height: 40px;    
    padding: 0px;
    box-shadow: 0px 2px 4px -4px;
}

.fa-search{
    color: #888888;
    position: relative;
    top:2px;
    left: 15px;
    z-index: 1;
}
.employee-input:focus {
    box-shadow: none !important;
    outline: none !important;
}
.input-group>.form-control:focus,
.input-group>.form-select:focus {
    z-index: 0 !important;
}
/* INPUT FIELDS END */

.employee-sea {
    position: relative;
    /* left: 145px; */
    /* bottom: 30px;        */
}
.employee-head {
    background-color: #F5FBFB;
    position: sticky;
    top: 0;
    border-radius: 20px !important;
}

.employee-act {
    color: #00979E;
}

.emp-card {
    border: none;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
}
tbody>tr:last-child>td {
    border: 0;
} 
tbody>tr{
    vertical-align: middle;
    overflow-wrap: break-word;
}

th:first-child {
    padding-left: 20px;
}
td:first-child {
    padding-left: 20px !important;
}

th:last-child {
    padding-right: 10px;
}

td:last-child {
    padding-right: 10px !important;
}


.tab-head {
    color: #888888;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02rem;
}

.tab-body {
    color: #2E2E2E;
    font-size: 14px;
    max-width: max-content;
}
.employee{ 
margin-top: 20px;
}
.employee-scroll {
    position: relative;
    height: 450px;
    overflow: auto;
    padding: 0;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border: none;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
}

/* width */
.employee-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 151, 158, 0.3) !important;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 151, 158, 0.3) !important;
}

.employee-table>:not(:first-child) {
    border-top: none !important;
}

.employee-img {
    border: none;
}

.employee-button {
    align-items: center;
    color: #00979E !important;
    border-color: #00979E !important;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 10px;
    line-height: 1.2;
}

.employee-button:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
}
.employee-button:focus {    
    box-shadow: none;
}
.tab-body .btn-delete{
    border: none;
    background-color: transparent;
    color: #00979E;
    line-height: 1.2;
    padding: 6px 10px;
    border-radius: 10px;
    align-items: center;
    vertical-align: middle;       
}
.tab-body .btn-delete .bi {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}
.model-title-delete{
    align-items: center;
}


/* Very Small devices (landscape phones, 576px and down) */

@media (max-width:320px) {
    .employee-scroll {
        margin: 0 0 0 20px;
        width: 85%;
    }

    .emp-input {
        height: 40px;
        margin: 10px 0 0 25px;
    }
}

@media (min-width:321px) and (max-width:576px) {
    .employee-scroll {
        margin: 0 0 0 6%;
        width: 88%;
    }

    .emp-content {
        width: 100% !important;
        position: relative;
        margin: 0 0 0 2% !important;
    }

    .employee-title {
        margin: 0;
    }
    
    .employee-summary {
        margin: 30px 0 0 10px;
    }

    .employee-select {
        margin: 5px 0;
    }

    .emp-input {
        height: 40px;
        margin: 8px 0 0 24px;
    }
}

/* Small devices (landscape phones, 576px and up) */


/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .employee-scroll {
        margin: 0 0 0 26px !important;
        width: 98% !important;
    }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width:1199px) {
    .emp-content {
        width: 105%;
        margin: 0 0 0 -5%;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width:1399px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}

