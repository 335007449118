/* LOGIN PAGE */

input:focus {
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #ced4da;
}

.top-logo {
    margin: 15px 0 0 0;
}

.img-thumbnail {
    border: none !important;
}

.right-side {
    background-color: #00979E !important;
    bottom: 0;
}

.my-form {
    margin-top: 50px;
}

.form-check {
    text-align: right;
    margin: -25px 0 0 0;
}

.login-text {
    margin-bottom: 25px;
    font-size: 22px;
    margin-top: 5px;
}

.login-with-otp .otp-button{
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    padding: 10px 15px;
    background-color: #00979E;
    color: #ffffff;
    box-shadow: none;
    border: none;
    border-radius: 20px !important;
    line-height: 1.2;
}

.email-verify-msg{
    color: #00979E;
    font-weight: 400;
    margin: 5px;
}
.login-status-msg{
    position: relative;
    top:5px;
}

.login-status-msg.fails{
    color: red;
    font-size: 14px;
    font-weight: 400;
}
.login-status-msg.success {
    color: green;
    margin: 10px 5px;
    font-size: 15px;
    font-weight: 400;
}

.alert-message-model{
    justify-content: center;
    align-items: center;
    text-align: center;
}

.login-with-otp .otp-button:hover  {
    background-color: #027075;
    color: #ffffff;
    box-shadow: none;
    border: none;
}
.login-with-otp .otp-button:focus {   
    box-shadow: none;
    border: none;
    background-color: #027075;    
}



.alert-danger{
    position: relative;
}
/* .otp-login-failed-message{
    align-items: center;
    text-align: center;
    background-color: #858585;
    color: #ffffff;
    padding: 10px;
} */

div[disabled] {
    pointer-events: none;
    opacity: 0;
}
.otp-button[disabled]{
    opacity: 0.3;
    background-color: #696969;
    outline: none;
    border: none;
}

.wel {
    margin: 0;
    color: #888888;
    font-size: 16px;
}

.email,
.password {
    font-size: 15px;
}
.form-control.login{
    border-radius: 10px;
}

.for-pass {
    color: #00979E;
}

.login-btn {
    color: #00979E !important;
    border-color: #00979E !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    box-shadow: none;
    border-radius: 10px;
}

.pass-box {
    margin: -10px 0;
}

.login-btn:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
    border-radius: 10px;
}

#exampleInputText1 {
    border: 1px solid rgba(136, 136, 136, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    height: 35px;
}

.logo-end {
    position: relative;
    top: 45%;
    right: 20%;
    width: 360px;
}

.icon {
    margin-top: 75px;
}

.icon1 {
    margin: -10px 0 0 0;
    overflow-x: hidden;
    overflow-y: hidden;
}

.myround {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 170px;
    margin: 0 0 0 0px;
}

.right-side {
    min-height: 100vh;
}

.right-round {
    position: fixed;
    width: 200px;
    margin-right: -1px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}

.for-pass {
    font-size: 12px;
    font-weight: bold;
}

/* Very Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) {

    .right-side,
    .myround,
    .icon,
    .right-round {
        display: none;
    }

    .wel,
    .login-text,
    .email,
    .password {
        display: none !important;
    }

    .my-form {
        margin: 7% 10% 10% 10% !important;
    }

    .for-pass {
        font-size: 12px;
    }

    .d-grid.gap-2 {
        margin: 0;
    }

    .brand-logo {
        text-align: center;
    }

    .top-logo {
        margin: 30px 0;
        width: 57%;
    }

    .login-left {
        margin: 120px 0 !important;
    }

    .form-check {
        margin: -15px 0;
    }

    .wel,
    .login-text,
    .email,
    .password {
        display: none !important;
    }

    .logo {
        text-align: center;
    }

    .login-btn {
        color: #fff !important;
        border-color: #00979E !important;
        background-color: #00979E !important;
        font-size: 16px !important;
        font-weight: bold !important;
    }

    .login-left {
        margin: 120px 0 !important;
    }

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
    .logo-end {
        position: absolute;
        margin: 220px 0 0 85px;
    }

    .my-form {
        margin: 30px 0 !important;
    }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width:1200px) {}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1201px) and (max-width:1400px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1401px) {}