.alert-message-model.invoice-created-status .modal-content {
    width: 100%;
    border: none;
    padding: 0px !important;
    border-radius: 10px;
    /* background-color: lightseagreen; */
    /* color: white; */
}

.alert-msg-box{
    padding: 10px 15px;
}

.alert-status-msg.fails {
    color: red;
    padding: 5px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 400;
    margin: 5px;
    align-items: center;
}
.alert-status-msg.success{
    color: green;
    padding: 5px;
    margin: 5px;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    align-items: center;
}

.alert-status-msg.success .fa{
    padding-right: 5px;
    padding-left: 5px;
    font-size: 18px;
}