
.branch-title {
    color: #00979E;
    font-size: 16px;
    margin: 10px 0 0 80px;
    font-weight: 700;
}

.branch-content{
    width: 98% !important;
    position: relative !important;
}

.branch-summary {
    margin-top: 20px;
    margin-left: 10px;
}

.branch-select {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #00979E;
    color: #00979E !important;
    height: 40px;
}
.branch-select:focus {
    box-shadow: none;
    border: 1px solid #00979E;
}
.fa-search {
    color: #888888;
    position: relative;
    margin: 10px;
}
.branch-input{
    height: 40px;
    padding: 0px;
    box-shadow: 0px 2px 4px -4px;
}
.branch-input1:focus {
    box-shadow: none !important;
    outline: none !important;
}
.fa-search {
    color: #888888;
    position: relative;
    top: 2px;
    left: 15px;
    z-index: 1;
}
.branch-sea {
    position: relative;
    flex: auto;
}

.branch-head {
    background-color: #F5FBFB;
    position: sticky;
    top: 0;
    border-radius: 20px !important;
}
.branch{
    margin-top: 20px;
}
.branch-inv,
.branch-po,
.branch-pp {
    color: #00979E;
}

.branch-po-tbody {
    color: #00979E;
    cursor: pointer;
}

.branch-thead {
    color: #888888;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02rem;    
}

.branch-tbody {
    color: #2E2E2E;
    font-size: 14px;
    max-width: max-content;
}

.branch-scroll {
    position: relative;
    height: 450px;
    overflow: auto;
    padding: 0;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border: none;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
}

/* width */
.branch-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 151, 158, 0.3) !important;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 151, 158, 0.3) !important;
}

.branch-table>:not(:first-child) {
    border-top: none !important;
}

.down-button {
    align-items: center;
    color: #00979E !important;
    border-color: #00979E !important;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 10px;
    line-height: 1.2;
}

.down-button:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
}

.down-button:focus{
    box-shadow: none;
}

.branch-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* /////// DROPDOWN SELECT & SEARCH START ////// */

.seleting-menus {
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
}

.seleting-menus .select {
    margin: 5px 5px !important;
    box-shadow: none;
    border: 1px solid #c2c2c2;
    color: #4e4e4e !important;
    max-width: fit-content;
    font-size: 13px !important;
    height: 30px !important;
    border-radius: 10px !important;
}

.seleting-menus select:focus {
    box-shadow: none;
    border: 1px solid #c2c2c2;
    color: #4e4e4e;
}

.btn-select-search {
    background-color: #00979E;
    margin-left: 5px;
    color: #ffffff;
    box-shadow: none;
    position: relative;
    align-items: center;
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 15px;
}

.btn-select-search:hover {
    color: #ffffff;
    box-shadow: none;
    background-color: #027075;
}

.btn-select-search:focus {
    box-shadow: none;
}

.btn-inline-item {
    align-items: flex-end;
}

.btn-inline-item h6 {
    padding-left: 5px;
    align-items: center;
    margin: 0px;
}

.btn-clear-select {
    background-color: #3f3f3f;
    color: #ffffff;
    align-items: center !important;
    padding: 8px 8px;
    margin-left: 8px;
    font-size: 14px;
    border-radius: 15px;
    line-height: 0;
}

.btn-clear-select:focus {
    box-shadow: none;
    background-color: #3f3f3f;
    color: #ffffff;
}

.btn-clear-select:hover {
    background-color: #070707;
    color: #ffffff;
}

.btn-clear-select .bi-x-circle-fill {
    text-align: center;
    vertical-align: middle;
}
/* /////// DROPDOWN SELECT & SEARCH END ////// */

/* Very Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) {
    .branch-title {
        margin: 0;
    }

    .branch-summary {
        margin: 30px 0 0 -10px;
        width: 107%;
    }

    .branch-select {
        margin: 5px 0;
    }

    .branch-input {
        margin: 8px 0 15px 24px !important;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .branch-title {
        margin: 10px 0 0 50px;
    }

    .branch-scroll {
        margin: 0 0 0 25px !important;
        width: 98% !important;
    }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width:1199px) {
    .branch-content {
        width: 100%;
        margin: 0 0 0 -5%;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) and (max-width:1399px) {
    .branch-content {
        width: 102%;
        margin: 0 0 0 -2%;
    }
} */

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}