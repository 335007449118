
.porder-cont {
    width: 98%;
}

.porder-summary {
    margin-top: 30px;
    margin-left: 10px;
}

.porder-select {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #00979E;
    color: #00979E !important;
    height: 40px;
}
.porder-input{
    height: 40px;
    padding: 0px;
    box-shadow: 0px 2px 4px -4px;
}
.porder-select:focus {
    box-shadow: none;
    outline: none !important;
    border: 1px solid #00979E;
    color: #00979E;
    border: 1px solid #00979E;
}
.fa-search {
    color: #888888;
    position: relative;
    top: 2px;
    left: 15px;
    z-index: 1;
}
.porder-sea {
   position: relative;
   width: 40%;
   margin-right: 20px !important;
   flex: auto;
}
.purchse-order{
    margin-top: 20px;
}
.porder-input12:focus {
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #ced4da;
}
::placeholder {
    color: rgba(136, 136, 136, 0.5) !important;
    font-size: 14px;
    line-height: 14px;
}
.porder-act {
    color: #00979E;
    text-decoration: none;
    width: max-content;
    background-color: transparent;
    border: none;
    /* padding: 0px !important; */
    font-size: 13px;
    cursor: pointer;
}
.porder-act:hover {
    color: #00979E;
    text-decoration: underline;
}

.porder-act .bi{
    padding-right: 5px;
    font-size: 14px;
}

.porder-act:focus {
    box-shadow: none !important;
}
/* /////// DROPDOWN SELECT & SEARCH START ////// */

.seleting-menus {
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
}


.seleting-menus .from-to-date-picker .select {
    margin: 0px !important;
    box-shadow: none;
    border: 1px solid #c2c2c2;
    color: #4e4e4e !important;
    max-width: fit-content;
    font-size: 13px !important;
    height: 30px !important;
    border-radius: 0px !important;
}

.seleting-menus select:focus {
    box-shadow: none;
    border: 1px solid #c2c2c2;
    color: #4e4e4e;
}

/* DatePicker CSS END  */
.react-datepicker-popper[data-placement^=bottom]{
    z-index: 2;
}
.select.from-date{
    width: 90px !important;
    padding: 2px 5px 2px 5px !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range{
    background-color: #00979E !important;
}
.react-datepicker__header{
    background-color: #F5FBFB !important;
}

/* DatePicker CSS END  */

.btn-select-search {
    background-color: #00979E;
    margin-left: 5px;
    color: #ffffff;
    box-shadow: none;
    position: relative;
    align-items: center;
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 15px;
}

.btn-select-search:hover {
    color: #ffffff;
    box-shadow: none;
    background-color: #027075;
}

.btn-select-search:focus {
    box-shadow: none;
}

.btn-inline-item {
    align-items: baseline;
}

.btn-inline-item h6 {
    padding-left: 5px;
    align-items: center;
    margin: 0px;
}

.btn-clear-select {
    background-color: #3f3f3f;
    color: #ffffff;
    align-items: center !important;
    padding: 8px 8px;
    margin-left: 8px;
    font-size: 14px;
    border-radius: 15px;
    line-height: 0;
}

.btn-clear-select:focus {
    box-shadow: none;
    background-color: #3f3f3f;
    color: #ffffff;
}

.btn-clear-select:hover {
    background-color: #070707;
    color: #ffffff;
}

.btn-clear-select .bi-x-circle-fill {
    text-align: center;
    vertical-align: middle;
}

/* /////// DROPDOWN SELECT & SEARCH END ////// */
.seleting-menus .input-group {
    width: auto;
    /* margin-right: 5px !important; */
}
.from-to-date-picker .input-group-text{
    border-right: none !important;
}
.from-to-date-picker #to-label{
    border-left: none;
}


.porder-scroll td {
    color: #2E2E2E;
    font-size: 14px;
    width: max-content;
}
.porder-table th {
    color: #888888;
    font-size: 14px;
    z-index: 1;
    /* padding: 15px 10px 15px 10px !important; */
    position: sticky;
    top: 0 !important;
    width: max-content;
    background-color: #F5FBFB;
}

/* .porder-table th:nth-child(15) {
    background-color: #F5FBFB !important;
    width: 100px !important;
    min-width: 105px !important;
    position: sticky !important;
    right: 0;
    z-index: 200 !important;
} */

/* .porder-table td:nth-child(15) {
    background-color: rgb(255, 255, 255) !important;
    width: 100px !important;
    min-width: 105px !important;
    position: sticky !important;
    right: 0;
} */

.porder-table th:last-child {
    background-color: #F5FBFB !important;
    width: 100px !important;
    min-width: 105px !important;
    position: sticky !important;
    right: 0;
    /* z-index: 200 !important; */
    /* top: 0; */
}

.porder-table td:last-child {
    background-color: rgb(255, 255, 255) !important;
    width: 100px !important;
    min-width: 105px !important;
    position: sticky !important;
    right: 0;
}


.po-thead {
    color: #888888;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
}

.po-tbody {
    color: #2E2E2E;
    font-size: 14px;
}
.porder-scroll {
    position: relative;
    height: 450px;
    overflow: auto;
    padding: 0;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border: none;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
}

/* .porder-table {
    width: max-content;
} */
.porder-table>:not(:first-child) {
    border-top: none !important;
}

.porder-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.porder-button {    
    align-items: center;
    color: #00979E !important;
    border-color: #00979E !important;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 10px;
    line-height: 1.2;
}

.porder-button:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
}

.porder-button:focus{
    box-shadow: none;
}

.porder-scroll {
    position: relative;
    overflow: auto;
}

/* width */
.porder-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 151, 158, 0.3) !important;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 151, 158, 0.3) !important;
}

/* Very Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) {
    .porder-title {
        margin: 0;
    }

    .porder-select {
        margin: 5px 0;
    }

    .porder-input {
        margin: 8px 0 15px 24px !important;
    }

    .porder-scroll {
        width: 90%;
        margin: 0 0 0 6%;
    }

    .porder-summary {
        margin: 30px 0 0 11px;
        width: 99%;
    }

    .porder-table td:nth-child(15) {
        border-bottom: 1px solid #EFEFEF !important;
    }
    .porder-table th:nth-child(15) {
        background-color: #F5FBFB !important;
        z-index: 999 !important;
        width: 89px !important;
        min-width: 89px !important;
        position: sticky !important;
        right: -30px;
        top: 0;
    }    
    .porder-table td:nth-child(15) {
        background-color: rgb(255, 255, 255) !important;
        width: 90px !important;
        min-width: 90px !important;
        position: sticky !important;
        right: -30px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .porder-scroll {
        margin: 0 0 0 25px !important;
        width: 98%;
    }
    .porder-table th:nth-child(15) {
        background-color: #F5FBFB !important;
        z-index: 999 !important;
        width: 100px !important;
        min-width: 105px !important;
        position: sticky !important;
        right: -30px;
        top: 0;
    }
    
    .porder-table td:nth-child(15) {
        background-color: rgb(255, 255, 255) !important;
        width: 90px !important;
        min-width: 90px !important;
        position: sticky !important;
        right: -30px;
    }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width:1199px) {
    .porder-cont {
        width: 100%;
        margin: 0 0 0 -2%;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .porder-cont {
        width: 98%;
    }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .expand {
        width: auto;
        margin-left: -12%;
        position: relative;
        min-height: calc(100vh - 60px);
        padding: 0 8px 52px 8px;
    }
}