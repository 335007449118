.pay-cont {
    width: 98%;
}
.payment-summary {
    margin-top: 30px;
    margin-left: 10px;
}

.payment-title {
    color: #00979E;
    font-size: 16px;
    margin: 10px 0 0 10px;
    font-weight: 700;
}

.payment-select {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #00979E;
    color: #00979E !important;
    height: 40px;
}

.payment-select:focus {
    box-shadow: none;
    border: 1px solid #00979E;
}

.payment-input {
    height: 40px;
    padding: 0px;
    box-shadow: 0px 2px 4px -4px;
}
.fa-search {
    color: #888888;
    position: relative;
    top: 2px;
    left: 15px;
    z-index: 1;
}
.date-choose{
    display: inline-flex;
}

.react-date-picker__wrapper{
    height: 40px;
    border-radius: 5px;
    border-color: #00979E !important;
}
.react-date-picker__inputGroup {
    color: #00979E;
}
.react-date-picker__inputGroup__input {
    color: #00979E !important;
    width: 18px !important;
    padding: 0px 5px !important;
    border: none !important;
}
.react-date-picker__inputGroup__input:focus {
    outline: none !important;
    color: #00979E !important;
}
.react-date-picker__inputGroup__input:invalid {
    background: transparent !important;
}
.react-date-picker__clear-button{
    color: #00979E !important;
    display: none !important;
}
.react-date-picker__calendar-button {
color: #00979E !important;
}
.bi-calendar3 {
    content: "\F214";
    color: #00979E;
    position: relative;
    top: 9px;
    left: -25px;
}

.payment-sea {
    position: relative;
    width: 40%;
    margin-right: 20px !important;
    flex: auto;
}

.paystatus-input:focus {
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #ced4da;
}

.payment-table>:not(:first-child) {
    border-top: none !important;
}

.payment-head {
    background-color: #F5FBFB;
    position: sticky;
    top: 0;
    border-radius: 20px !important;
    text-align: left;    
}
.paymentsta{
    margin-top: 20px;
}
.paymentsta-scroll {
    position: relative;
    height: 450px;
    overflow: auto;
    padding: 0;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border: none;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
}

/* /////// DROPDOWN SELECT & SEARCH START ////// */

.seleting-menus {
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
}

.seleting-menus .from-to-date-picker .select {
    margin: 0px !important;
    box-shadow: none;
    border: 1px solid #c2c2c2;
    color: #4e4e4e !important;
    max-width: fit-content;
    font-size: 13px !important;
    height: 30px !important;
    border-radius: 0px !important;
}

.seleting-menus select:focus {
    box-shadow: none;
    border: 1px solid #c2c2c2;
    color: #4e4e4e;
}

.btn-select-search {
    background-color: #00979E;
    margin-left: 5px;
    color: #ffffff;
    box-shadow: none;
    position: relative;
    align-items: center;
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 15px;
}

.btn-select-search:hover {
    color: #ffffff;
    box-shadow: none;
    background-color: #027075;
}

.btn-select-search:focus {
    box-shadow: none;
}

.btn-inline-item {
    align-items: flex-end;
}

.btn-inline-item h6 {
    padding-left: 5px;
    align-items: center;
    margin: 0px;
}

.btn-clear-select {
    background-color: #3f3f3f;
    color: #ffffff;
    align-items: center !important;
    padding: 8px 8px;
    margin-left: 8px;
    font-size: 14px;
    border-radius: 15px;
    line-height: 0;
}

.btn-clear-select:focus {
    box-shadow: none;
    background-color: #3f3f3f;
    color: #ffffff;
}

.btn-clear-select:hover {
    background-color: #070707;
    color: #ffffff;
}

.btn-clear-select .bi-x-circle-fill {
    text-align: center;
    vertical-align: middle;
}

/* /////// DROPDOWN SELECT & SEARCH END ////// */

/* width */
.paymentsta-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 151, 158, 0.3) !important;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 151, 158, 0.3) !important;
}

.pay-thead {
    color: #888888;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02rem;
}

.pay-act {
    text-decoration: underline;
    color: #00979E !important;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
}

.pay-tbody {
    font-size: 14px;
}

.paystatus-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paystatus-button {
    align-items: center;
    color: #00979E !important;
    border-color: #00979E !important;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 10px;
    line-height: 1.2;
}

.paystatus-button:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
}
.paystatus-button:focus {
    box-shadow: none;
}
.btn-viewpayment-details{
    box-shadow: none;
    color: #00979E;
    text-decoration: none;
    font-size: 13px;
    margin: 0px;
    padding: 0px;
}
.btn-viewpayment-details:hover {
    box-shadow: none;
    color: #00979E;
    text-decoration: underline;
}
.btn:focus{
    box-shadow: none;
}
.pay-paid {
    background-color: rgba(0, 151, 158, 0.15);
    border-radius: 5px;
    color: #00979E;
    font-size: 12px;
    padding: 5px 20px;
}

.pay-pen {
    background-color: rgba(204, 51, 0, 0.15);
    border-radius: 5px;
    color: #ED4F35;
    font-size: 12px;
    padding: 5px 10px;
}
/* .bi-calendar3::before {
    content: "\f214";
    color: #00979E;
    position: relative;
    top: 10px;
    right: 20px;
} */
.react-date-picker__button:enabled {
    cursor: pointer;
    visibility: hidden;
}

/* Very Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) {
    .payment-title {
        margin: 0;
    }

    .payment-select {
        margin: 5px 0;
    }

    .payment-input {
        margin: 8px 0 0 24px;
    }

    .mydate {
        margin: 0 0 20px 0;
    }

    .react-date-picker__wrapper {
        margin-bottom: 20%;
    }

    .paymentsta-scroll {
        width: 95%;
        margin: 0 0 0 4%;
    }

    .payment-summary {
        margin: 30px 0px 0px 0;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .paymentsta-scroll {
        margin-left: 25px;
        width: 98%;
    }

   
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}