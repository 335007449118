
/* #togglemenu {
    background-color: #00979E;
} */

/* EXPAND PAGE WRAPPER  START*/
.expand{
    width: auto ;
    margin-left: -15%;
    margin-right: 0px;
    position: relative;
    min-height: calc(100vh - 60px);
    padding: 0 8px 52px 8px;
}

.toggle-shift-left{
    margin-left: -160px !important;
}

.logged-in-by{
    position: relative;
    direction: rtl;
    top: 5px;
    right: 5px;
}

.login-user-name-email{
    padding: 0px;
    margin: 0px;
    color: #00979E;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
.login-type{
    padding: 0px;
    font-size: 13px;
    text-transform: capitalize;
}
.notification-list .dropdown-menu{
    min-width: 8rem !important;
    /* max-height: 14vh; */
}
.dropdown-menu.show.user-profile-logout{
    border: none;
    /* background-color: #00979E; */
    border-radius: 15px 0px 15px 15px;
    box-shadow: 0px 0px 2px 0px lightslategrey;
    margin: 4px 5px 0px 0px !important;
}
.dropdown-menu.show.user-profile-logout:hover{
    background-color: #ffffff;
}
.log-out-btn{
    /* background-color: #ffffff; */
    border-radius: 6px 0px 7px 7px;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    color: #00979E;
    font-weight: 500;
}
.log-out-btn .bi-box-arrow-right{
    font-size: 18px;
    font-weight: 900;
}
.log-out-btn:hover {
    background-color: #00979E;    
    color: #ffffff;
}
/* EXPAND PAGE WRAPPER  END*/

/* TOGGLE REACT START*/

.menu {
    background: #00979E;
    height: 100vh;
    width: 250px;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    transition: all 0.25s ease;
    transform: translateX(-50%);
}
.active {
    opacity: 1;
    visibility: visible;
    transition: all 0.25s ease;
    transform: translateX(0);
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

/* TOGGLE REACT END*/

.leftbar-tab-menu .main-menu-inner .navbar-vertical .navbar-nav {
    margin-top: 65px;
}

.pro {
    width: 38px !important;
    height: 38px !important;
}

ul#tab-menu {
    margin: 50px 0 0 0;
}

i.fa.fa-bars {
    color: #00979E !important;
}

.topbar {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    border-bottom: 1px solid #eceff5;
    z-index: 2222;
    background: white;
}

.leftbar-tab-menu .main-menu-inner .menu-body .main-icon-menu-pane {
    display: unset !important;
}

.navbar-custom {
    background: #fff;
    min-height: 60px;
    position: relative;
    margin-left: 260px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.notification-list .alert-badge::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    top: 5px;
    right: 15px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #ef4d56;
}

.leftbar-tab-menu .main-icon-menu {
    width: 60px !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px 0;
    background-color: #00979E;
    height: 100% !important;
    z-index: 10010;
    position: fixed;
    top: 0;
}

.leftbar-tab-menu .main-icon-menu .logo-metrica .logo-sm {
    height: 34px;
}

.navbar-custom .nav-link {
    padding: 0;
    color: #00979E;
    margin: 0 8px;
}

.navbar-content-img{
    margin-right: 20px;
}

.leftbar-tab-menu .main-icon-menu .main-icon-menu-body {
    overflow: hidden;
    width: 100%;
    max-height: 100%;
}

.navbar-custom .nav-link.nav-icon {
    width: 36px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff;
    border-color: #00979E;
    border-style: solid;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar-custom .nav-link.nav-icon i {
    font-size: 20px;
    padding-bottom: 2px;    
}

.navbar-custom .dropdown-toggle:after {
    content: initial;
}

.navbar-custom .topbar-nav {
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* margin: 0 15px 0 0; */
}

.notification-list .notification-menu {
    max-height: 220px;
    margin-left: 0;
}

.notification-list .alert-badge::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    top: 5px;
    right: 15px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #ef4d56;
}

.leftbar-tab-menu .main-icon-menu {
    width: 60px !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    padding: 16px 0;
    background-color: #00979E;
    height: 100% !important;
    z-index: 10010;
    position: fixed;
    top: 0;
}

.leftbar-tab-menu .main-icon-menu .logo-metrica .logo-sm {
    height: 34px;
}

.leftbar-tab-menu .main-icon-menu .main-icon-menu-body {
    overflow: hidden;
    width: 100%;
    max-height: 100%;
}

.leftbar-tab-menu .main-icon-menu .main-icon-menu-body .nav {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 60px;
}

.leftbar-tab-menu .main-icon-menu .main-icon-menu-body .nav .nav-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 10px 0;
}

.leftbar-tab-menu .main-icon-menu .main-icon-menu-body .nav.nav-tabs {
    border-bottom: none;
}

.leftbar-tab-menu .main-icon-menu .main-icon-menu-body .nav.nav-tabs .nav-link {
    border: none;
    position: relative;
    padding: 0;
    color: #fff;
    width: 36px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #007E83;
    border-radius: 5px;
    /* font-weight: 500; */
}

.leftbar-tab-menu .main-icon-menu .main-icon-menu-body .nav.nav-tabs .nav-link.active,
.leftbar-tab-menu .main-icon-menu .main-icon-menu-body .nav.nav-tabs .nav-link:focus {
    background-color: #007E83;
    border-color: transparent;
    color: #fff !important;
}

#dashboard-tab .bi{
    color: white !important;
    font-size: 22px;
    position: relative;
    top: 2px;
}

.leftbar-tab-menu .main-icon-menu .main-icon-menu-body .nav.nav-tabs .nav-link:hover {
    color: #fff;
}

.emp-link.active::before {
    content: url(../images/empmaster-active.png);
    margin-right: 15px;
    margin-left: 10px;
}

.emp-link::before {
    content: url(../images/empmaster.png);
    margin-right: 15px;
}
.branch-link.active::before {
    content: url(../images/Branch-active.png);
    margin-right: 15px;
    margin-left: 10px;
}
.branch-link::before {
    content: url(../images/Branch.png);
    margin-right: 15px;
}
.vendor-link.active::before {
    content: url(../images/vendor-active.png);
    margin-right: 15px;
    margin-left: 10px;
}

.vendor-link::before {
    content: url(../images/vendor.png);
    margin-right: 15px;
}
.purchase-link.active::before {
    content: url(../images/Purchaseorders-active.png);
    margin-right: 15px;
    margin-left: 10px;
}

.purchase-link::before {
    content: url(../images/Purchaseorders.png);
    margin-right: 15px;
}
.invo-link.active::before {
    content: url(../images/Invoicestatus-active.png);
    margin-right: 15px;
    margin-left: 10px;
}

.invo-link::before {
    content: url(../images/Invoicestatus.png);
    margin-right: 15px;
}
.payment-link.active::before {
    content: url(../images/PaymentStatus-active.png);
    margin-right: 15px;
    margin-left: 10px;
}

.payment-link::before {
    content: url(../images/PaymentStatu.png);
    margin-right: 15px;
}

/* .leftbar-tab-menu .main-icon-menu .main-icon-menu-body .nav.nav-tabs .nav-link.active::before{
    content: '';
    position: absolute;
    left: 40px;
    top: 10px;
    border-right: 10px solid #fff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
} */


.leftbar-tab-menu .main-icon-menu .pro-metrica-end {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    margin-top: auto;
}


.leftbar-tab-menu .main-menu-inner {
    width: 200px;
    height: 100%;
    background-color: #F5FBFB;
    border-right: 1px solid #eceff5;
    position: fixed;
    top: 0;
    left: 60px;
    z-index: 1001;
    border-right: 1px solid #eceff5;
}

.leftbar-tab-menu .main-menu-inner .menu-body {
    padding: 10px 0;
    height: calc(100vh - 60px) !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.leftbar-tab-menu .main-menu-inner .menu-body .nav-item {
    position: relative;
    display: block;
}

.leftbar-tab-menu .main-menu-inner .menu-body .nav-item .nav-link {
    position: relative;
    color: rgba(110, 110, 110, 0.5);
    font-size: 14px !important;
    font-weight: 400;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 38px;
    padding: 0px 20px;
}

.leftbar-tab-menu .main-menu-inner .menu-body .nav-item .nav-link.active,
.leftbar-tab-menu .main-menu-inner .menu-body .nav-item .nav-link.active i {
    color: #00979E;
    font-size: 14px !important;
    font-weight: 500 !important;
    background-color: #fff;
    padding: 0px 0px 0px 10px;
    
}

.enlarge-menu .leftbar-tab-menu {
    min-width: 60px;
}

.enlarge-menu .leftbar-tab-menu .main-menu-inner {
    display: none;
    -webkit-transition: opacity .3s linear, left .3s ease-out;
    transition: opacity .3s linear, left .3s ease-out;
}

.enlarge-menu .topbar .navbar-custom {
    margin-left: 60px;
}

.enlarge-menu .topbar .topbar-left {
    margin-left: 0;
    width: 260px;
}

.enlarge-menu.enlarge-menu-all .leftbar-tab-menu {
    display: none;
}

.enlarge-menu.enlarge-menu-all .topbar .topbar-left {
    margin-left: 0;
    width: 260px;
}

.enlarge-menu.enlarge-menu-all .topbar .navbar-custom {
    margin-left: 0;
}

.enlarge-menu .page-content-tab {
    width: calc(100% - 60px);
}


@media (max-width:1199.98px) {
    .page-wrapper .page-content-tab {
        width: calc(100% - 50px) !important;
    }
}

.left-sidebar .navbar-vertical .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true] {
    color: #303e67;
    background: #f5f5f9;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.page-wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-x: hidden !important;
    padding-bottom: 20px;
}
.navbar-custom .responsive-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 10px;
}

.page-wrapper .page-content-tab {
    width: calc(100% - 245px);
    margin-left: auto;
    position: relative;
    min-height: calc(100vh - 60px);
    /* padding: 0 8px 52px 8px; */
    margin-top: 50px;
    margin-right: 5px;
}

.enlarge-menu .page-content-tab {
    width: calc(100% - 60px);
}

.accordian_recentactivity {
    position: relative;
    margin-top: -27px;
}



/* RESPONSIVE CODE FOR MEDIA START */
@media (max-width:320px) {
    .page-wrapper {
        overflow-x: hidden !important;
    }
    .expand {
        width: 112% !important;
        margin-left: -15% !important;
    }
    .navbar-custom .nav-link.nav-icon { 
        position: relative;
        margin-left: -25px !important;
    }
    .navbar-custom {
        margin-left: 50px;
    } 
    .page-wrapper .page-content-tab {
        width: calc(100% - 20px) !important;
    }
    .toggle-shift-left {
        margin-left: -50px !important;
    }
    .leftbar-tab-menu .main-icon-menu{
        display: none;
    }
    .leftbar-tab-menu .main-menu-inner{
        margin-left: -60px;
    }
   .accordian_sorting{
       margin-top: -30px;
   }
  
   .table{
       width : max-content;
   }
   .dash-title{
        text-align: left;
   }
    .accordian_recentactivity{
        float: left;
        margin-left: 140px !important;
        margin-top: 15px !important;
        margin-bottom: 15px;
        z-index: 1;
    }
    .cselect {
        margin: -20px 0px 10px 0;
    }
    /* .pay {
        width: 75px;
        margin: 5px auto;
    } */
    .act-body {
        margin: -10px 0;
    }
    .ven-text {
        font-weight: 400;
        font-size: 12px;
        margin-bottom: auto;
        margin-left: 8px;
    }
    .emp-content {
        width: 95% !important;
    }
    .dash-content {
        width: 100% !important;
        position: relative;
        margin: 0 0 0 9px;
    }
    .page-wrapper .page-content-tab{
    margin-left: 25px;
    /* width: 98% !important; */
    }
    .navbar-custom .topbar-nav{
        margin-right: 40px !important;
    }
    .rec-date {
        margin: -5px -10px 15px 0 !important;
    }
    .pro {
        width: 35px !important;
        margin:-10px -15px 0 35px !important;
    }
    .my-bell {
        margin: 10px -25px 0px 0px;
    }
    
        
}
@media (max-width:580px) {
    .dropdown-recent-activity{width:100px !important;}
}

/* ------- Till 320px--------------- */

@media (min-width:321px) and (max-width:576px) {
    .page-wrapper {
        overflow-x: hidden !important;
    }
    .expand {
        margin-left: -10%;
        padding: 0 25px 52px 8px;
    }
    #togglemenu {
        margin-left: -535% !important;
    }
    .navbar-custom .nav-link.nav-icon {
        position: relative;
        margin-left: -200px !important;
    }

    .toggle-shift-left {
        margin-left: -50px !important;
    }

    .leftbar-tab-menu .main-icon-menu {
        display: none;
    }

    .leftbar-tab-menu .main-menu-inner {
        margin-left: -60px !important;
    }
    .page-wrapper .page-content-tab {
        margin-right: -20px !important;
    }
    .accordian_sorting {
        margin-top: -30px;
    }

    .table {
        width: max-content;
    }

    .dash-title {
        text-align: left;
    }

    .accordian_recentactivity {
        margin-top: -30px !important;
        z-index: 1;
    }

    .cselect {
        margin: -20px 0px 10px 0;
    }

    .ven-text {
        font-weight: 400;
        font-size: 12px;
        margin: -20% 0px 0px 40px;
    }

    .dash-content {
        width: 95% !important;
        margin-left: 15px;
    }

    .page-wrapper .page-content-tab {
        margin-right: -15px !important;
        width: 97%;
    }

    .pro {
        margin: 0px 0 0 0;
    }

    /* .pay {
        width: 70px;
        margin: 0px;
    } */
    .navbar-custom .topbar-nav {
        margin: 0;
    }
    
    .pay-cont {
        width: auto !important;
        margin-left: -4%;
    }
    
    .date-choose {
        margin: 0 0 5% 0;
    }
    
    .row.paymentsta {
        width: 100%;
        margin-left: 3%;
    } 
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
    .expand {
        width: auto !important;
        margin-left: -11% !important;
    }

    .main-icon-menu {
        display: none;
    }

    .main-menu-inner.menu.active {
        margin: 0 0 0 -8% !important;
    }

    .navbar-custom .nav-link.nav-icon {
        margin-left: -530% !important;
    }

    .paymentsta {
        margin-left: 2%;
        width: 101%;
    }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width:1199px) {
    .expand {
        width: auto;
        margin-left: -8% !important;
    }

    .main-menu-inner.menu.active {
        margin: 0 0 0 -6% !important;
    }

    .main-icon-menu {
        display: none;
    }

    .navbar-custom .nav-link.nav-icon {
        margin-left: -500% !important;
    }

    .navbar-custom .topbar-nav {
        margin: 0 30px 0 0 !important;
    }
}

/* X-Large devices (large desktops, 1400px and up) */
@media (min-width: 1400px) {
    /* .navbar-custom .topbar-nav {
        margin: 0 20px 0 0 !important;
    } */

    .expand {
        width: auto;
        margin-left: -17%;
    }
}

/* RESPONSIVE CODE FOR MEDIA END */

@media (min-width: 1201px)  {
    .dash-content {
        width: 98% !important;
        margin: 0 0 0 25px !important;
    }

    /* .pay {
        width: 80px;
        margin: 0px;
    } */

    .ven-text {
        font-size: 14px;
        text-align: justify;
        width: auto;
        margin-bottom: 0px;
        margin-left: 10px;
    }

    .vendor {
        height: auto;
        /* padding: 5px 10px; */
    }

    .ven-date {
        font-size: 10px;
    }
}

/* RESPONSIVE CODE FOR MEDIA END */


