@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,400;8..144,500;8..144,600;8..144,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@400;500;600;700&display=swap');


@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");


* {
/* font-family: 'Roboto', sans-serif ; */
/* font-family:'Mukta',    sans-serif; */
    /* font-family: 'Fira Sans', sans-serif; */
    /* font-family: 'Signika Negative', sans-serif; */
    /* font-family: 'Assistant', sans-serif; */
/* font-family: 'Sarabun', sans-serif; */
/* font-family: 'Noto Sans Display', sans-serif; */
/* font-family: 'Yantramanav', sans-serif; */
/* font-family: 'Sarabun', sans-serif; */
/* font-family: 'Hind Madurai', sans-serif; */
/* font-family: 'Roboto Flex', sans-serif; */
font-family: 'Vazirmatn', sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
body{
    font-size: 14px;
}