
.paymentdetails-view {
    width: 98%;
    position: relative;
}
.view-paydetails{
    margin-top: 30px;
    margin-left: 10px;
    display: flex;
}
.payment-details-item{
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
}
.main-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.view-back {
    width: max-content;
    align-items: center;
    background-color: transparent;
    color: #000;
    border: none;
    margin: 0px 0px;
    display: flex;
}
.view-back:hover {
    color: #000;
}

.view-back:focus {
    box-shadow: none !important;
}
.line {
    display: flex;
    flex: auto;
    text-align: center;
}
.paydetail-head {
    font-weight: bold;
    flex: auto;
}
.paymentdetails-view .btn-download-excel{
    outline-color: #00979E;
    border-color: #00979E;
    color: #00979E;
    border-radius: 20px;
    font-size: 14px;
    padding: 8px 20px;
    line-height: 1.5;
}
.paymentdetails-view .btn-download-excel:hover {
    background-color: #00979E;
    border-color: #00979E;
    color: #ffffff;
}
.viewpage {
    margin-top: 25px;
    margin-left: 20px;
}
.viewpage-scroll {
    position: relative;
    overflow: auto;
    padding: 0;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border: none;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
}