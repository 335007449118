
.inv-cont {
    width: 98% !important;
}

.sea-inv {
    margin: 0 0 0 -60px;
}

.invoice-summary {
    margin-top: 30px;
    margin-left: 10px;
}

.invoice-title {
    color: #00979E;
    font-size: 16px;
    margin: 10px 0 0 40px;
    font-weight: 700;
}

.modal {
    background-color: rgba(128, 128, 128, 0.335) !important;
    z-index: 99999;
}

.invoice-select {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #00979E;
    color: #00979E !important;
    height: 40px;
}

.invoice-select:focus {
    box-shadow: none;
    border: 1px solid #00979E;
}
textarea:focus {
    box-shadow: none !important;
    border: 1px solid #888888 !important;
}

.fa-search {
    color: #888888;
    position: relative;
    top: 2px;
    left: 15px;
    z-index: 1;
}

.invoice-sea {
    position: relative;
    width: 30%;
    margin-right: 20px !important;
    flex: auto;
}
.invoice-status {
    margin-top: 20px;
}

.invoice-input{
    height: 40px;
    padding: 0px;
    box-shadow: 0px 2px 4px -4px;
}
.invoice-input1:focus {
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #ced4da;
}
.istatus-scroll {
    position: relative;
    height: 450px;
    overflow: auto;
    padding: 0;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border: none;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
}

/* /////// DROPDOWN SELECT & SEARCH START ////// */

.seleting-menus {
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
}

.seleting-menus .from-to-date-picker .select {
    margin: 0px !important;
    box-shadow: none;
    border: 1px solid #c2c2c2;
    color: #4e4e4e !important;
    max-width: fit-content;
    font-size: 13px !important;
    height: 30px !important;
    border-radius: 0px !important;
}

.seleting-menus select:focus {
    box-shadow: none;
    border: 1px solid #c2c2c2;
    color: #4e4e4e;
}

.btn-select-search {
    background-color: #00979E;
    margin-left: 5px;
    color: #ffffff;
    box-shadow: none;
    position: relative;
    align-items: center;
    padding: 6px 10px;
    font-size: 12px;
    border-radius: 15px;
}

.btn-select-search:hover {
    color: #ffffff;
    box-shadow: none;
    background-color: #027075;
}

.btn-select-search:focus {
    box-shadow: none;
}

.btn-inline-item {
    align-items: flex-end;
}

.btn-inline-item h6 {
    padding-left: 5px;
    align-items: center;
    margin: 0px;
}

.btn-clear-select {
    background-color: #3f3f3f;
    color: #ffffff;
    align-items: center !important;
    padding: 8px 8px;
    margin-left: 8px;
    font-size: 14px;
    border-radius: 15px;
    line-height: 0;
}

.btn-clear-select:focus {
    box-shadow: none;
    background-color: #3f3f3f;
    color: #ffffff;
}

.btn-clear-select:hover {
    background-color: #070707;
    color: #ffffff;
}

.btn-clear-select .bi-x-circle-fill {
    text-align: center;
    vertical-align: middle;
}

/* /////// DROPDOWN SELECT & SEARCH END ////// */


/* width */
.istatus-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #00979E;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #00979E;
}

.istatus-table>:not(:first-child) {
    border-top: none !important;
}

.invoice-head {
    background-color: #F5FBFB;
    position: sticky;
    top: 0;
}

.invoice-thead,
.sta-head {
    color: #888888;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02rem;
}
 

.insta-act {
    color: #00979E !important;
    text-decoration: none !important;
    font-size: 14px;
    font-weight: 400;
}

.invoice-tbody {
    color: #2E2E2E;
    font-size: 14px;
}


.invsta-tbody {
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 10px 15px 10px !important;
}

.edit-link,
.view-link {
    color: #00979E !important;
    cursor: pointer;
    font-size: 14px;
}
/* Upload Button Here */
.file {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file>input[type='file'] {
    display: none
}

.file>label {
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
    border-style: solid;
    border-radius: 4px;
    border-width: 1px;
    background-color: hsl(0, 0%, 100%);
    color: hsl(0, 0%, 29%);
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2% 36%;
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
}

.uppdf {
    margin-right: 15px;
}

/* Upload Button Here */
.edit-img,
.view-img {
    border: none;
}

.dot-sta-closed {
    background-color: rgba(0, 151, 158, 0.15);
    border-radius: 15px;
    color: #00979E;
    font-size: 14px;
    padding: 6px 12px;
}
.dot-sta-open {
    background-color: rgba(204, 51, 0, 0.15);
    border-radius: 15px;
    color: #ED4F35;
    font-size: 14px;
    padding: 5px 25px;
}

.edit-img {
    margin: -3px 0 0 0 !important;
}
.ver-dots {
    border: 1px solid #00979E;
    border-radius: 5px;
    color: #00979E;
    margin: 0 0 0 5px;
}
.edit-modal,
.load-modal,
.view-modal {
    width: 95% !important;
    height: auto !important;
    margin-top: -5%;
}
.payment-model {
    background-color: #b2aeae8c;
    z-index: 99999;
}
.payment-model-head{
    background-color: #effafa;
    border-radius: 20px !important;
    text-align: center;
    padding: 5px;
    color: #00979E;
    font-size: 20px;
}
.pay-head {
    color: #00979E;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.inv-input {
    height: 30px !important;
    margin-bottom: 5px;
}

.input-model-label{
    margin-top: 0px !important;
    font-size: 13px;
}
.inv-num {
    color: #00979E;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.my-input {
    margin-top: 15px;
}

.my-text {
    margin-top: 10px;
}

.upload {
    color: #fff !important;
    border-color: green !important;
    background-color: green !important;
    border-radius: 20px;
    font-size: 14px;
    line-height: 1.8;
}
.progress-bar {
    background-color: #3db3b9;
}

.save-up {
    color: #00979E !important;
    border-color: #00979E !important;
    background-color: #fff !important;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 15px;
    letter-spacing: 1px;
}

.save-up:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
}
.mydrop {
    color: #6c757d !important;
    background-color: #fff !important;
    border-color: #fff !important;
    margin: -45px 0 0 145px;
}

.mydrop:after {
    display: none !important;
}

.menu-action {
    transform: translate(-25px, 3px) !important;
    border: none;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
}
.mytextarea {
    margin-top: 15px;
}

.save-doc {
    text-align: right;
    color: #00979E !important;
    border-color: #00979E !important;
    margin-top: 5px;
    padding: 3px 15px;
}

.save-doc:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
    margin-top: 5px;
    padding: 3px 15px;
}

.inv-payment,
.doc-no,
.due-date,
.pay-date,
.my-amount,
.myref,
.mypay,
.youref,
.yourpay,
.naration {
    font-size: 12px;
}

input[type=text] {
    height: 25px;
    font-size: 12px;
}

.myinvoive-head {
    text-align: center;
    color: #00979E;
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
}

/* .myvoice {
    margin-top: 5px;
} */

.excel-down {
    color: #00979E !important;
    border-color: #00979E !important;
    padding: 2px 10px !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    margin-right: 10px;
}

.download-dropdown{
    min-width: 140px;
    background-color: #f8faff;
    border: none;
    border-radius: 15px;
}

.excel-down:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
    padding: 2px 10px;
    border-radius: 5px;
    box-shadow: none;
}

/* .pdf {
    /* width: 80%; */
    /* margin: 10%; */
    /* background-color: #ffffff; */

    /* margin: 8% 0 0 4%; */
/* } */ 

/* .ok-pdf {
    color: #00979E !important;
    border-color: #00979E !important;
    font-size: 12px;
    font-weight: 600;
    margin: 20px 0;
    padding: 3px 20px !important;
}

.ok-pdf:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
    padding: 3px 20px !important;
} */

.btn-primary {
    color: #00979E !important;
    border-color: #00979E !important;
    background-color: #ffff;
}
.btn-primary.invoice-button {    
    align-items: center;
    color: #00979E !important;
    border-color: #00979E !important;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 10px;
    line-height: 1.2;
}

.btn-primary.invoice-button:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
}

.btn-primary.invoice-button:focus {
    box-shadow: none;
}


.invoice-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.istatus-scroll {
    position: relative;
    overflow: auto;
}


/* width */
.istatus-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 151, 158, 0.3) !important;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 151, 158, 0.3) !important;
}

/* Very Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) {
    .invoice-title {
        margin: 0 0 5px 0;
    }

    .invoice-select {
        margin: 5px 0;
    }

    .invoice-input {
        margin: 8px 0 0 24px;
    }

    .invoice-right {
        margin: 20px 0 0 10px;
    }

    .invoice-summary {
        margin: 30px 0 0 -4%;
        width: 113%;
    }

    .istatus-scroll {
        margin: 0 0 0 1%;
    }

    .ver-dots {
        margin-left: -30px;
    }

    .menu-action {
        transform: translate(10px, 3px) !important;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .istatus-scroll {
        margin: 0 0 0 25px !important;
        width: 98% !important;
    }

    .menu-action {
        transform: translate(15px, 3px) !important;
    }
}

/*  Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width:1399px) {
    .menu-action {
        transform: translate(-15%, 3px) !important;
    }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}