.view-summary {
    margin-top: 30px;
    margin-left: 10px;
    display: flex;
}

.porder-cont-view{
    width: 98%;
    position: relative;
}
.main_model{
    background-color: #b8b8b896;
    z-index: 9999;
}
.modal-content{
    width: 100%;
    padding: 10px;
    }

.view-back {
    width: max-content;
    align-items: center;
    background-color: transparent;
    color: #000;
    border: none;
    margin: 0px 0px;
    display: flex;
}
.view-back>span{
    font-size: 16px;
    position: relative;
    top: 2px;
    left: 2px;
}
.main-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.view-back:hover{
    color: #000;
}

.view-back:focus {
    box-shadow: none !important;
}

.arrow {
    border: solid #2E2E2E;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
.line{
    display: flex;
    flex: auto;
    text-align: center;
}
.total {
    color: #00979E;
    font-weight: bold;
    margin-right: 30px;
}

.pur-total {
    font-weight: bold;
    flex: auto;
}
.view-tbody .input-qty-edit{
    max-width: 60px !important;
    border-radius: 0px !important;    
}
.view-tbody .input-qty-edit:visited {
    max-width: 60px !important;
    border-radius: 0px !important;
    border: none;
}
.view-tbody .input-qty-edit:focus {
    max-width: 60px !important;
    border-radius: 0px !important;
    /* border: none; */
}
.view-tbody .wrong-qty-msg{
    font-size: 10px;
    color: red;
    padding-top: 3px;
}

.pdf-img{
    border: none;
}
#invoice-lineitem-checkbox{
    margin: 4px 4px 4px 0px !important;
    position: relative;
    top: 3px;
}
.up-btn {
    justify-content: right;
}

.upload-pdf {
    color: #00979E !important;
    border-color: #00979E !important;
    float: right !important;
    font-size: 14px;
    font-weight: 500;
    width: auto;
    text-transform: uppercase;
    align-items: center;
    border-radius: 15px;
    line-height: 1.8;
}


.upload-pdf:hover {
    color: #fff !important;
    border-color: #00979E !important;
    background-color: #00979E !important;
}
.upload-pdf .fa-upload{
    font-size: 15px;
    /* font-weight: 600; */
}

.view-details {
    margin-top: 20px;
    width: 98%;
    margin-left:26px !important;
}
.view-card-body{
    border-radius: 15px;
}
.right-details {
    border-left: 2px solid #EFEFEF;
}
.inv-upload-btn{
    margin: 0px 10px;
}
.pro-bar-div {
    display: flex;
    width: 100% !important;
    justify-content: flex-end !important;
    align-items: center !important;
    text-align: center !important;
    margin: 1% 1% !important;
}
.pro-bar {
    width: 30% !important
}

.view1 {
    margin-top: 5px;
}

.views-thead {
    background: #F5FBFB;
}
.view-head{
    font-size: 15px;
    font-weight: 500;
}
.view-thead {
    color: #888888;
    font-size: 14px;
    font-weight: 600;
    background-color: #F5FBFB;
}

.view-body {
    color: #2E2E2E;
    font-size: 14px;
    font-weight: 400;
    overflow-wrap: break-word;
}
.view-body .status-pending{
    color: red;
}
.view-body .status-sent {
    color: green;
}

.viewpage {
    margin-top: 25px;
    margin-left: 20px;
}
.view-tbody{
    font-size: 13px;
    overflow-wrap: break-word;
}
/* .view-table {
    border: none;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
    min-height: auto;
} */

.view-table>:not(:first-child) {
    border-top: none !important;
}
.invoice-save-btn {
    display: inline-flex;
    padding: 10px 0px !important;
    justify-content: flex-end !important;
    width: 100% !important;
}

/* .view-thead {
    background-color: #F5FBFB;
} */

.viewpage-scroll {
    position: relative;
    overflow: auto;
    padding: 0;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    border: none;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
    border-radius: 5px;
}
/* width */
.viewpage-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 151, 158, 0.3) !important;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 151, 158, 0.3) !important;
}

@media screen and (max-width: 768px) {

    .download-excel,
    .upload-pdf {
        margin: 2%;
    }
}