.pagination {
    margin: 25px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: #00979E;
    border-color: #00979E;
    color: #ffffff;
}


.pagination>li>a {
    border: 1px solid #00979E;
    padding: 5px 15px;
    font-size: 14px;
    /* border-radius: 5px; */
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: #00979E;
    border-color: #00979E;
    outline: none;
    color: #ffffff;
}

.pagination>li>a,
.pagination>li>span {
    color: #00979E;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: unset;
}
.pagination>li:first-child>a{
    border-radius: 15px 0px 0px 15px;
}
.pagination>li:last-child>a{
    border-radius: 0px 15px 15px 0px;
}
.pagination>.disabled a{
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
}

.page-range{
    display: inline-flex;
    margin-left: 20px;
}